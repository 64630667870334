import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "mt-5",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkForm()
        },
      },
    },
    [
      _vm.user_prop.registerUrl && _vm.user_prop.isTech
        ? _c(
            VRow,
            { staticClass: "ml-3", attrs: { align: "center" } },
            [
              _c("h5", [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("alpha_register") + ":") + " "),
                ]),
                _c("span", [
                  _vm._v(" " + _vm._s("" + _vm.user_prop.registerUrl) + " "),
                ]),
              ]),
              _c(
                VBtn,
                {
                  staticClass: "ml-3",
                  attrs: { "x-small": "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.gs.copy_url_to_clipboard(
                        _vm.user_prop.registerUrl
                      )
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { color: "primary" } }, [
                    _vm._v("mdi-content-copy"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VRow,
        { staticClass: "ml-3 mt-5" },
        [
          _c(VTextField, {
            attrs: { label: "Empresa parceira" },
            model: {
              value: _vm.user.name,
              callback: function ($$v) {
                _vm.$set(_vm.user, "name", $$v)
              },
              expression: "user.name",
            },
          }),
        ],
        1
      ),
      _vm.user_prop.alphaRegisterUrlsLang &&
      _vm.user_prop.alphaRegisterUrlsLang.length > 0 &&
      !_vm.user_prop.isTech
        ? _c(
            "div",
            _vm._l(_vm.user_prop.alphaRegisterUrlsLang, function (alphaUrl, i) {
              return _c(
                VRow,
                {
                  key: i,
                  staticClass: "ml-0 last-mt-4",
                  attrs: { align: "center" },
                },
                [
                  alphaUrl
                    ? _c(
                        "div",
                        { staticClass: "alphaRegisterUrlsLangContainer" },
                        [
                          _c("h5", [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("alpha_register") +
                                    "[" +
                                    _vm.getAlphaRegisterUrlLang(alphaUrl) +
                                    "]:"
                                )
                              ),
                            ]),
                            _c("span", [
                              _vm._v(" " + _vm._s("" + alphaUrl) + " "),
                            ]),
                          ]),
                          _c(
                            VBtn,
                            {
                              staticClass: "ml-3",
                              attrs: { "x-small": "", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.gs.copy_url_to_clipboard(alphaUrl)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { color: "primary" } }, [
                                _vm._v("mdi-content-copy"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.user_prop.bankerRegisterUrl && !_vm.user_prop.isTech
        ? _c(
            VRow,
            { staticClass: "ml-0 mt-5", attrs: { align: "center" } },
            [
              _c("h5", [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(_vm._s(_vm.$t("banker_register") + ":")),
                ]),
                _c("span", [
                  _vm._v(
                    " " + _vm._s("" + _vm.user_prop.bankerRegisterUrl) + " "
                  ),
                ]),
              ]),
              _c(
                VBtn,
                {
                  staticClass: "ml-3",
                  attrs: { "x-small": "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.gs.copy_url_to_clipboard(
                        _vm.user_prop.bankerRegisterUrl
                      )
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { color: "primary" } }, [
                    _vm._v("mdi-content-copy"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.is_admin
        ? _c(
            VRow,
            { staticClass: "ml-0", attrs: { align: "center" } },
            [
              _c(
                VCol,
                { attrs: { sm: "12", md: "6" } },
                [
                  _c("p", { staticClass: "colorpickerTitle" }, [
                    _vm._v(_vm._s(_vm.$t("comanager_title"))),
                  ]),
                  _c(VCheckbox, {
                    staticClass: "field",
                    attrs: { color: "primary", outlined: "", dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [_vm._v(_vm._s(_vm.$t("comanager")))]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      642916389
                    ),
                    model: {
                      value: _vm.IsCoManager,
                      callback: function ($$v) {
                        _vm.IsCoManager = $$v
                      },
                      expression: "IsCoManager",
                    },
                  }),
                  _c("p", { staticClass: "colorpickerTitle" }, [
                    _vm._v(_vm._s(_vm.$tc("partner", 1))),
                  ]),
                  _c(VCheckbox, {
                    staticClass: "field",
                    attrs: { color: "primary", outlined: "", dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("partner_dxa_tech_title"))
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2316805004
                    ),
                    model: {
                      value: _vm.IsTech,
                      callback: function ($$v) {
                        _vm.IsTech = $$v
                      },
                      expression: "IsTech",
                    },
                  }),
                  _c("p", { staticClass: "colorpickerTitle" }, [
                    _vm._v(_vm._s(_vm.$t("market_app_label"))),
                  ]),
                  _c(VCheckbox, {
                    staticClass: "field",
                    attrs: { color: "primary", outlined: "", dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [_vm._v(_vm._s(_vm.$t("access_market_app")))]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2508771771
                    ),
                    model: {
                      value: _vm.AccessMarket,
                      callback: function ($$v) {
                        _vm.AccessMarket = $$v
                      },
                      expression: "AccessMarket",
                    },
                  }),
                  _vm.IsCoManager
                    ? _c(
                        VSelect,
                        {
                          attrs: {
                            disabled: !_vm.IsCoManager,
                            items: _vm.companies,
                            label: _vm.$t("companies"),
                            multiple: "",
                            outlined: "",
                            dense: "",
                            "item-text": "Name",
                            "item-value": "CompanyId",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("select_companies_comanager")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            332411564
                          ),
                          model: {
                            value: _vm.selectedCompanies,
                            callback: function ($$v) {
                              _vm.selectedCompanies = $$v
                            },
                            expression: "selectedCompanies",
                          },
                        },
                        [_vm._v(" >")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VRow,
        { staticClass: "mx-auto", attrs: { align: "center" } },
        [
          _c(
            VCol,
            { attrs: { sm: "12", md: "3" } },
            [
              _c("p", { staticClass: "colorpickerTitle" }, [
                _vm._v(_vm._s(_vm.$t("company_color"))),
              ]),
              _c(VColorPicker, {
                attrs: { mode: "hexa", elevation: "5" },
                on: {
                  change: function ($event) {
                    return _vm.showColor()
                  },
                },
                model: {
                  value: _vm.picker,
                  callback: function ($$v) {
                    _vm.picker = $$v
                  },
                  expression: "picker",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { sm: "12", md: "9" } },
            [
              _c(
                VRow,
                { attrs: { align: "center" } },
                [
                  _c(VCol, { attrs: { cols: "6", md: "6" } }, [
                    _c("p", { staticClass: "colorpickerTitle" }, [
                      _vm._v(_vm._s(_vm.$t("logo"))),
                    ]),
                    _c("input", {
                      ref: "imageLogoInput",
                      staticClass: "ml-0",
                      attrs: {
                        id: "imageLogoInput",
                        name: "imageLogoInput",
                        type: "file",
                      },
                      on: { change: _vm.setImageLogo },
                    }),
                    _c("p", { staticClass: "alert-size-image-message mt-1" }, [
                      _vm._v(" " + _vm._s(_vm.$t("alert_size_image")) + " "),
                    ]),
                  ]),
                  _c(
                    VCol,
                    {
                      staticClass: "pb-0 ma-1",
                      staticStyle: { display: "flex", "align-items": "end" },
                      attrs: { cols: "12", md: "5" },
                    },
                    [
                      _c(VCheckbox, {
                        attrs: { "hide-details": "" },
                        model: {
                          value: _vm.isCropSelected,
                          callback: function ($$v) {
                            _vm.isCropSelected = $$v
                          },
                          expression: "isCropSelected",
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("crop")))]),
                      _c(
                        VTooltip,
                        {
                          staticStyle: { "align-items": "end" },
                          attrs: { left: "", "max-width": "300px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "white",
                                            elevation: "0",
                                            icon: "",
                                            dark: "",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        VIcon,
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-help-circle")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("crop_description"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.isCropSelected == true && _vm.user.fileString
                    ? _c(
                        VCol,
                        [
                          _c("cropper", {
                            staticClass: "cropper",
                            staticStyle: {
                              "max-width": "483px",
                              height: "210px",
                            },
                            attrs: { src: _vm.showPhoto() },
                            on: { change: _vm.change },
                          }),
                        ],
                        1
                      )
                    : _vm.user.companyLogoPath || _vm.user.fileString
                    ? _c(VCol, [
                        _c("img", {
                          attrs: {
                            id: "selected-company-logo",
                            src: _vm.showPhoto(),
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { sm: "12" } },
            [
              _c(VTextField, {
                attrs: { readonly: "", label: _vm.$t("partner_url") },
                model: {
                  value: _vm.user.showcaseLink,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "showcaseLink", $$v)
                  },
                  expression: "user.showcaseLink",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { sm: "12" } },
            [
              _c(VTextField, {
                attrs: { readonly: "", label: _vm.$t("partner_auth_token") },
                model: {
                  value: _vm.user.partnerAuthToken,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "partnerAuthToken", $$v)
                  },
                  expression: "user.partnerAuthToken",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { sm: "12" } },
            [
              _c(VTextField, {
                attrs: { label: _vm.$t("update_webhook") },
                model: {
                  value: _vm.user.updateWebhook,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "updateWebhook", $$v)
                  },
                  expression: "user.updateWebhook",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VCardActions,
        { staticClass: "pl-0 dxa_modal_actions" },
        [
          _c(
            VBtn,
            {
              staticClass: "dxa_modal_btnSuccess",
              attrs: {
                disabled: !_vm.imageSizeIsValid,
                color: "primary",
                "min-width": "100",
                loading: _vm.loading,
                type: "submit",
              },
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          ),
          _c(
            VBtn,
            {
              staticClass: "dxa_modal_btnError",
              attrs: { color: "secondary", "min-width": "100" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("go_back")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }